@import '../../styles/variables.scss';

$border-style: 1px solid var(--nav-border-color, $nav-border-color);

.navigation {
  grid-column: 1;
  grid-row: 1 / 2;
  display: grid;
  grid-template-rows: auto $nav-footer-height;
  grid-template-columns: auto;
  height: 100vh;
  background: var(--nav-background-color, $nav-background-color);
}

.items {
  grid-column: 1;
  grid-row: 1;
  border-bottom: $border-style;
  border-right: $border-style;
  overflow: hidden;
}

.footer {
  grid-column: 1;
  grid-row: 2;
  border-right: $border-style;
}
