@import '../../../styles/common.scss';

$separator-border-style: 1px solid $light-gray;
$header-button-icon-color: var(--light-blue, $light-blue);

.menuContainer {
  border-right: $separator-border-style;

  &.hidden {
    border-right: none;
    width: 0;
    z-index: -1;
  }

  .menu {
    border: none;
    background: none;
    padding: 0;
    padding: 0px 20px 0 25px;
    width: 75px;
    align-self: center;
    height: 100%;
    cursor: pointer;

    svg {
      height: 30px;
      width: 30px;
      * {
        stroke: var(--header-button-icon-color, $header-button-icon-color);
      }
    }
  }
}
