@import '../styles/common.scss';

.root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 0px 1fr;
  grid-template-rows: $nav-header-height 100%;
  transition: width ease-in 0.3s;

  &.rootNav {
    grid-template-columns: $nav-width 1fr;
    .nav {
      width: $nav-width;
      box-shadow: 5px 0 5px -5px var(--nav-border-color, $nav-border-color);
    }
  }

  &.overlayNav {
    @extend .rootNav;
    .main {
      grid-column: span 2;
    }
    .header {
      grid-column: 2;
    }
  }
}

.header {
  text-align: center;
  grid-column: 2;
  grid-row: 1;
}

.main {
  height: calc(100vh - #{$nav-header-height});
  overflow-y: auto;
  grid-column: 2;
  grid-row: 2;

  > div {
    display: grid;
    overflow-y: auto;
  }
}

.nav {
  grid-column: 1;
  grid-row: 1 / 2;
  overflow-x: hidden;
  width: 0px;
  transition: width $nav-transition-duration;
  height: 100vh;
  z-index: 20;
  background-color: var(--nav-background-color, $nav-background-color);
}

.slideContent {
  display: grid;
}

.toastContainer {
  width: $toast-message-width + 8px; // 8px is the padding
  top: 16px;
  right: 16px;

  .toast {
    min-height: auto;
    border-radius: 0;
    padding: 0;

    .toastBody {
      padding: 0;
      margin: 0;
      align-items: start;
    }
  }
}
