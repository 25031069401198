.loading {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-items: center;
  background-color: #00467d;

  .loadingContent {
    width: 100%;
    max-width: 200px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    place-items: center;
    row-gap: 24px;

    .loadingMessage {
      text-align: center;
      font-size: 24px;
      color: #b7b7b7;
    }

    .overlay {
      animation: opacity 2s infinite;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
}
