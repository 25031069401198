@import '../../../styles/common.scss';

.container {
  @include boxSizing;

  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  justify-content: left;
  font-weight: normal;

  a {
    text-decoration: none;
  }

  .hidden {
    visibility: hidden;
    position: absolute;
  }

  .crumbChevron {
    z-index: 1;
    height: 15px;
    path {
      stroke: var(--breadcrumbs-chewron-color, $breadcrumbs-chewron-color);
    }
  }

  .homeButton {
    display: grid;
    place-items: center;

    svg {
      height: 26px;
      padding: 0 15px;

      polygon,
      path {
        stroke: var(--header-homebutton-color, $breadcrumbs-text-color);
      }
    }
  }

  .moreIcon {
    width: 20px;
    height: 20px;
    padding-top: 10px;

    path {
      fill: var(
        --breadcrumbs-text-color-active,
        $breadcrumbs-text-color-active
      );
    }

    &:hover {
      border-bottom: 1px solid
        var(--breadcrumbs-text-color-active, $breadcrumbs-text-color-active);
    }
  }

  // shared top level crumb item styling
  @mixin topeLevelCrumbs {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    align-items: center;

    background-color: var(
      --breadcrumbs-background-color,
      $breadcrumbs-background-color
    );

    color: var(--breadcrumbs-text-color, $breadcrumbs-text-color);

    white-space: nowrap;
    overflow: hidden;
  }

  .crumb {
    @include topeLevelCrumbs;

    span.crumbLabel {
      // ellipsis for label overflow
      display: inline-block;
      padding: 0 15px;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        z-index: 4;
      }
    }

    &:hover {
      text-decoration: underline;
    }

    // last crumb's styles
    &:last-of-type {
      font-weight: bold;

      color: var(
        --breadcrumbs-text-color-active,
        $breadcrumbs-text-color-active
      );
      background-color: var(
        --header-background-color,
        $header-background-color
      );

      svg {
        path {
          stroke: var(
            --breadcrumbs-text-color-active,
            $breadcrumbs-text-color-active
          );
        }
      }

      .crumbChevron {
        path {
          stroke: var(
            --breadcrumbs-background-color,
            $breadcrumbs-background-color
          );
        }
      }

      &:hover {
        color: var(--breadcrumbs-text-color, $breadcrumbs-text-color-active);
        text-decoration: none;

        .homeButton {
          svg {
            polygon,
            path {
              stroke: var(--header-homebutton-color, $header-homebutton-color);
            }
          }
        }
      }
    }
  }

  .dropDownAnchor {
    @include topeLevelCrumbs;

    overflow: inherit; // allows dropdown list to be shown outside breadcrumbs
    cursor: pointer;

    span {
      height: 100%;
      display: grid;
      align-items: center;
      position: relative; // is set here as this property causes issues with z-index and breadcrumbs arrows if set on parent

      .ellipsisLabel {
        padding: 0 10px;
      }

      .dropDownList {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 50px;
        width: 360px;

        position: absolute;
        top: $header-height;
        margin-top: -20px;

        z-index: 5;
        left: 0px;

        box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.2);

        a {
          display: grid;
          grid-template-columns: 1fr 40px;
          grid-template-rows: 1fr;

          padding-left: 20px;
          overflow: inherit;
          position: relative;

          background-color: var(
            --breadcrumbs-tooltip-background-color,
            $breadcrumbs-tooltip-background-color
          );

          color: var(
            --breadcrumbs-tooltip-text-color,
            $breadcrumbs-tooltip-text-color
          );

          &:hover {
            background-color: var(
              --breadcrumbs-tooltip-background-color-hover,
              $breadcrumbs-tooltip-background-color-hover
            );
            p {
              text-decoration: underline;
            }
          }

          .crumbLabel {
            justify-content: left;
          }

          .dropDownChevron {
            margin-left: 10px;
            height: 20px;
            align-self: center;
            path {
              stroke: var(
                --breadcrumbs-dropdown-chewron-color,
                $breadcrumbs-dropdown-chewron-color
              );
            }
          }
        }
      }
    }
  }
}
