@import '../../styles/common.scss';
@import 'react-toastify/dist/ReactToastify.css';

.container {
  display: grid;
  align-content: center;
  grid-template-rows: 1fr auto;
  color: var(--toast-text-color, $dark-gray);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);

  a {
    text-decoration-color: var(--link-color, $light-blue);
    color: var(--toast-link-color, $light-blue);
  }

  .header {
    grid-row: 1/2;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    color: $dark-gray;

    .typeIcon {
      grid-column: 1;
      grid-row: 1;
      height: 100%;
      font-size: 0;
      .icon {
        padding: 10px;
        width: 50px;
        height: 50px;
      }
    }

    .title {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
      padding: 10px;
      grid-column: 2;
    }

    .close {
      grid-column: 3;
      align-self: start;
      border: none;
      background: none;
      padding: 15px;
      height: 50px;
      font-size: 0;
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: var(--toast-close-button-color, $blue);
        }
      }
    }

    &.warning {
      background-color: rgba($yellow, 0.2);
      .typeIcon {
        background: var(--toast-warning-color, $yellow);
      }
    }

    &.error {
      background-color: rgba($red, 0.2);
      .typeIcon {
        background: var(--toast-error-color, $red);
      }
    }

    &.info {
      background-color: var(--info-color, $light-gray-2);
      .typeIcon {
        background: var(--info-icon-color, $dark-gray);
      }
    }

    &.success {
      background-color: rgba($green, 0.2);
      .typeIcon {
        background: var(--toast-success-color, $green);
      }
    }
  }

  .body {
    grid-row: 2/2;
    grid-row: 2;
    min-height: 50px;
    font-size: 14px;
    padding: 0 10px;
    display: grid;
    align-content: center;
  }
}
