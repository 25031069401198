@import '../../../styles/variables.scss';

.footer {
  height: 50px;
  display: grid;
  grid-template-columns: 20px max-content;
  font-size: 10px;
  color: var(--gray, $gray);
  font-weight: normal;
  align-items: center;
  padding-left: 20px;

  .link {
    text-decoration: unset;
    color: unset;
    display: contents;
  }

  .footerText {
    padding-left: 15px;
    display: grid;
    grid-template-rows: max-content max-content;

    .title {
      font-size: 11px;
    }

    .copyright {
      font-size: 9px;
      color: unset;
    }
  }
}
