.container {
  font-size: 1.5rem;
  align-content: start;
  text-align: center;

  .details {
    white-space: pre-wrap;
    font-size: small;
  }
}
