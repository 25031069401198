.container {
  display: grid;
  justify-content: end;
  grid-auto-flow: column;

  .indicators {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(70px, min-content);
    gap: 5px;
    padding: 0 5px;
    overflow-x: auto;

    .indicatorWrapper {
      display: grid;
      min-width: 70px;
      align-content: center;
      justify-content: center;
    }

    .indicatorEnterActive {
      animation-duration: 200ms;
      animation-name: fade-in;
    }

    .indicatorExitActive {
      animation-duration: 200ms;
      animation-name: shrink;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes shrink {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
        min-width: 70px;
      }

      100% {
        opacity: 0;
        min-width: 0;
        width: 0;
      }
    }
  }
}
