$dark-blue: #00467d;
$blue: #1478af;
$light-blue: #28aae1;
$red: #f06c55;
$dark-gray: #707070;
$gray: #b7b7b7;
$light-gray: #dddddd;
$light-gray-2: #eeeeee;
$yellow: #ffc81a;
$green: #95c852;

$nav-background-color: #fff;
$nav-border-color: $light-gray;
$nav-header-height: 70px;
$nav-width: 360px;
$nav-footer-height: 50px;
$nav-transition-duration: 500ms;

$toast-message-width: 360px;
