.container {
  width: 100%;
  padding: 15% 0 0;
  margin: auto;
}

.configBox {
  position: relative;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.configBox input {
  font-family: 'Roboto', sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.configBox .inputButton {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #1da9e1;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.configBox .inputButton:hover,
.configBox .inputButton:focus {
  background: rgba(40, 170, 225, 0.75);
}

.configBox .inputButton:active {
  background: #0082b6;
}

.configBox .disclaimer {
  color: #8d8d8d;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
}
