.container {
  display: grid;
  grid: min-content 1fr / 1fr;

  .wrapper {
    display: grid;
    grid-auto-flow: rows;

    justify-content: center;

    h1 {
      margin-top: 20%;
      margin-bottom: -10px;

      color: #f06c55;
      font-size: 120px;
      text-align: center;
    }

    .body {
      color: #707070;
      font-size: 14px;
      line-height: 1.4;
      text-align: center;

      br {
        margin: 5px 0px;
      }
    }

    .link {
      color: #28aae1;
      text-decoration: none;
      justify-self: center;
      text-decoration: underline;
    }
  }
}
