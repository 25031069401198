@import '../../../styles/common.scss';

.container {
  grid-column-start: -1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  align-items: center;

  position: relative;

  border-left: 1px solid var(--header-divider-color, $header-divider-color);

  .profileContainer {
    display: grid;
    grid-template-columns: 150px 43px;
    align-items: center;
    margin-left: 5px;
    height: 100%;

    .profilePicture {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }

    .userName {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 10px;
      cursor: pointer;
    }

    .button {
      cursor: pointer;
      place-items: center baseline;

      svg {
        height: 35%;
      }

      svg * {
        stroke: $blue;
      }
    }
  }

  .logoContainer {
    display: grid;
    grid-template-columns: 0px 1fr;
    width: 150px;

    .logo {
      width: 150px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 70%;
      }
    }
  }
  .menu {
    position: absolute;
    top: $header-height;
    right: 0px;
    width: 100%;
    background-color: white;
    border: 1px solid $gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  .logoutBtn {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    height: 60px;
    background-color: var(--header-logout-button, $header-logout-button);
    border: 0px;
    color: white;
    text-align: left;
    padding-left: 15px;
    padding-right: 0;
    font-size: 14px;

    &:hover {
      background-color: var(
        --header-logout-button-hover,
        $header-logout-button-hover
      );
      cursor: pointer;
    }

    svg {
      height: 18px;
      margin: 0 10px;
      vertical-align: top;
      stroke: white;
    }
  }
}
