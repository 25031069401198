// :root {
//   --landingpage-title-color: #00467d;
//   --landingpage-subtitle-color: #8c8b87;
//   --landingpage-largetile-background-color: rgba(89, 136, 166, 0.9);
//   --explorer-action-button-color: #28aae1;
//   --actions-background-color: #dddddd;
//   --landingpage-largetile-background-color: #28aae1;
//   --landingpage-largetile-disabled-background-color: #8c8b87;
//   --actions-hover-color: rgba(40, 170, 225, 0.75);
//   --filter-controller-background-color: #ebebeb;
//   --filter-background-color: #ffffff;
// }

body {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  margin: 0;
  font-family: Arial, Verdana, Tahoma, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > div#app {
    height: 100vh;
  }
}
