$light-gray: #ddd;
$dark-blue: #00467d;
$blue: #1478af;
$light-blue: #28aae1;
$gray: #b7b7b7;

/* Default colors */
$dark-blue: #00467d;
$blue: #1478af;
$light-blue: #28aae1;
$red: #f06c55;
$dark-gray: #707070;
$gray: #b7b7b7;
$light-gray: #dddddd;
$light-gray-2: #eeeeee;
$yellow: #ffc81a;
$green: #95c852;

$nav-background-color: #fff;
$nav-border-color: $light-gray;
$nav-header-icon-disabled-color: $gray;
$nav-header-height: 70px;
$nav-width: 360px;
$nav-footer-height: 50px;
$nav-transition-duration: 100ms;

/* Navigation vars */
$header-height: 70px; // Important: if this value is changed, breadcrumb arrow styling need to be adjusted as well.
$header-background-color: white;
$header-text-color: $dark-gray;
$header-homebutton-color: $light-blue;
$header-divider-color: $light-gray;
$header-border-bottom: $dark-blue;
$header-logout-button: $light-blue;
$header-logout-button-hover: rgba($light-blue, 0.75);

$breadcrumbs-background-color: white;
$breadcrumbs-text-color: $light-blue;
$breadcrumbs-tooltip-background-color: white;
$breadcrumbs-tooltip-background-color-hover: lighten(
  $breadcrumbs-tooltip-background-color,
  5%
);
$breadcrumbs-tooltip-text-color: $light-blue;
$breadcrumbs-chewron-color: $gray;
$breadcrumbs-dropdown-chewron-color: $light-blue;
$breadcrumbs-text-color-active: $blue;
