@import '../../styles/common.scss';

.container {
  @include boxSizing;

  display: grid;
  grid-template-columns: max-content 2fr 1fr max-content;
  grid-template-rows: $header-height;

  //border-bottom: 1px solid var(--header-border-bottom, $header-border-bottom);

  background-color: $header-background-color;
  color: var(--header-text-color, $header-text-color);
}
